<template>
    <div>
        <h1 class="page__title">Филиалы</h1>
        <div v-if="isSuperAdmin" class="actions__container">
            <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" to="/affiliates/create">
                Создать
            </router-link>
        </div>

        <ContentSearch />

        <ContentTable
            :inverse-toggle-icons="false"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @delete="deleteAction"
            @toggle="hideAction"
        />

        <CrudModal
            :isOpen="modalOpen"
            :title="modalTitle"
            :type="modalType"
            :accept-callback="modalCallback"
            :modal-data="modalData"
            @update:isOpen="modalOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import CrudModal from '@/components/modals/CrudModal';
import ContentSearch from '@/components/base/ContentSearch';
import { mapGetters } from 'vuex';

export default {
    components: { ContentSearch, CrudModal, ContentTable },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            affiliate: false,
            modalComponent: () => import('@/components/modals/ModalDelete'),
            modalOpen: false,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalData: null,
            tableActions: [],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'name', label: 'Название' },
                { field: 'address', label: 'Адрес' },
            ],
            tableData: [],
            pagination: null,
            affiliates: [],
        };
    },
    async created() {
        if (this.isSuperAdmin) {
            this.tableActions = [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'TrashIcon', cbName: 'delete' },
                { name: 'hide', title: 'скрыть', icon: 'toggle', cbName: 'toggle' },

            ];

            if (this.$route && this.$route.query && this.$route.query.affiliate_id) {
                this.affiliate = this.$route.query.affiliate_id;
            }
        } else {
            this.tableActions = [{ name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'toggle', title: 'скрыть', icon: 'toggle', cbName: 'toggle' },
            ];
        }

        await this.fetchData(this.getQuery());
    },
    computed: {
        ...mapGetters({
            isSuperAdmin: 'user/isSuperAdmin',
            getUser: 'user/getUser',
        }),
    },
    methods: {
        async fetchData(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/affiliates', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch data: ' + ex);
            }
        },
        getQuery(query = this.$route.query) {
            const affiliateIds =
                this.getUser && this.getUser.assigned_affiliates && this.getUser.assigned_affiliates.length > 0
                    ? this.getUser.assigned_affiliates.join(',')
                    : '';
            return this.isSuperAdmin ? query : Object.assign({}, query, { ids: affiliateIds });
        },
        editAction(row) {
            this.$router.push({ name: 'affiliatesEdit', params: { id: row.id } });
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalType = 'delete';
            this.modalOpen = true;
        },
        async hideAction(row) {
            const { test } = await this.axios.post('/affiliates-hide/' + row.id);
            await this.fetchData(this.getQuery());
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/affiliates/' + data.id)
                .then((res) => {
                    this.fetchData(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
    async beforeRouteUpdate(to, from, next) {
        const query = this.getQuery(to.query);
        await this.fetchData({ ...query });
        next();
    },
};
</script>

<style></style>
